<template>
    <div>
        <el-button v-if="!isSelect" plain @click="dialogVisible = true" class="select-video">
			选择知识扩展
		</el-button>

        <div class="select-knowledge-2-info" v-if="isSelect">
            <div class="select-knowledge-2-info-name">{{this.selectName}}</div>
            <div class="select-knowledge-2-info-option">
                <el-link type="primary" @click="reelectItem()">重选</el-link> -
                <el-link type="primary" @click="deleteItem()">删除</el-link>
            </div>
        </div>

        <el-dialog
                title="选择知识扩展"
                :visible.sync="dialogVisible"
                width="700px"
                append-to-body
                :before-close="handleClose">
            <div slot="title">
                我的知识扩展
            </div>

            <div class="knowledge-2-content" >
                <div class="all-knowledge-2">
                    <p class="knowledge-2-tag selected">全部知识扩展 <span class="knowledge-2-count">({{this.total}})</span> </p>
                </div>

                <div class="knowledge-2-list-content">

                    <div class="knowledge-2-list" v-loading="loading">
                        <p class="knowledge-search">
                            <el-input style="width: 180px" v-model="title" clearable placeholder="输入标题"></el-input>
                            <el-button plain @click="search" class="margin-l-sm">筛选</el-button>
                        </p>
                        <div v-for="(item,index) in lists" :key="index" >
                            <div class="knowledge-2-item" @click="selectItem(item.id,item.title)">
                                <span class="select-icon" v-if="selectId === item.id"></span>
                                <div class="knowledge-2-item-top">
                                    <div class="knowledge-2-info">
                                        <p class="knowledge-2-info-title">
                                            <el-tooltip effect="dark" :content="item.title" placement="top">
                                                <span>{{item.title}}</span>
                                            </el-tooltip>
                                        </p>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>

                        <div class="knowledge-search">
                            <el-button slot="trigger" size="small" @click="addItem" type="primary">添加知识扩展</el-button>
                            <div class="page fl-r" >
                                <el-pagination
                                        small
                                        background
                                        :current-page="page"
                                        :page-sizes="[10, 20]"
                                        :page-size="pageSize"
                                        layout="total, prev, pager, next "
                                        @size-change="setPageSize"
                                        @current-change="setPage"
                                        :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="select">确 定</el-button>
		   </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../utils/tools";

    export default {
        name: "selecKnowledge2",
        data(){
            return{
                isSelect:false,
                dialogVisible: false,
				lists: [],
                title:'',
                page:1,
                pageSize:10,
                total:0,
                loading:true,
                selectId:0,
                selectName:'',
                uploading:false,
				type:2 // 知识扩展
            }
        },
        methods:{
			...mapActions('knowledge',['getKnowledgeList']),
            select(){
                if(this.selectId == 0)
                {
                    this.$message.error('请选择知识扩展');
                    return
                }
                this.dialogVisible = false
                this.isSelect = true
                this.$emit('onSelect',this.selectId)
            },
            handleClose(done) {
                done();
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
					type: this.type
                }
                if(this.title!= '')
                {
                    data.title = this.title
                }
                this.getKnowledgeList(data).then(res => {
                    this.lists = res.data.list
                    this.total = res.data.total
                    this.loading = false
                })
            },
            search(){
                this.page = 1
                this.getList()
            },
            reelectItem(){
                this.dialogVisible = true
            },
            deleteItem(){
                this.isSelect = false
                this.$emit('onDelete')
            },
            addItem(){
                this.$router.push('/knowledge_2/add')
            },
            selectItem(id,name){
				this.selectId = id
				this.selectName = name
			}
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>
    .select-knowledge-2-info{
        padding: 20px;
        background-color: #fafafa;
        width: 160px;
    }
    .select-knowledge-2-info-name{
        width: 100%;
        color: #333;
        line-height: 20px;
        font-size: 12px;
        word-break:break-all
    }
    .select-knowledge-2-info-option{

    }
    .el-divider__text, .el-link{
        font-size: 12px;
    }
    .el-dialog__header{
        padding:20px;
    }
    .el-dialog__body{
        padding:0
    }
    .knowledge-2-content{
        height: 500px;
        border-bottom: 1px solid #ebebeb;
        border-top: 1px solid #ebebeb;
    }
    .all-knowledge-2{
        padding:20px 10px;
        float: left;
        height: 490px;
        overflow-y: scroll;
    }
    .selected{
        background: #f7f7f7;
    }
    .knowledge-2-tag{
        height: 32px;
        color: #333;
        font-size: 12px;
        padding : 0 10px;
        line-height: 32px;
        cursor: pointer;
        color: #999;
    }
    .knowledge-2-count{
        margin-left: 5px;
        font-size: 12px;
    }
    .knowledge-search{
        width: 100%;
        flex-grow: 2;
        padding-bottom:10px
    }
    .knowledge-2-list-content{
	
    }
    .knowledge-2-list{
        padding:20px 10px;
        display: flex;
        flex-wrap:wrap;
        justify-content:space-between
    }
    .el-card__body{
        padding:10px
    }
    .select-icon{
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 30px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
    }
    .knowledge-2-item{
        width: 260px;
        height: 72px;
        margin-bottom: 10px;
        border: 1px solid #ebebeb;
        cursor: pointer;
        position: relative;
        line-height: 1;
    }
    .knowledge-2-info{
        float: left;
        margin-left:10px
    }
    .knowledge-2-item-top{
        padding: 12px 10px 12px 12px;
    }
    .knowledge-2-info-title{
        color: #333;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
        width: 170px;
        height: 20px;
        overflow: hidden;
    }
    .knowledge-2-info-size{
        color: #999;
        font-size: 12px;
    }
    .knowledge-2-option{
        height: 36px;
        background: #fafafa;
        border-top:1px solid #ebebeb;

    }
    .clear{
        clear: both;
    }
</style>
