<template>
    <div>
        <el-button v-if="!isSelect" plain @click="dialogVisible = true" class="select-video">选择视频</el-button>

        <div class="select-file-info" v-if="isSelect">
            <div class="select-file-info-name">{{this.selectFileName}}</div>
            <div class="select-file-info-option">
                <el-link type="primary" @click="reelectFile">重选</el-link> -
                <el-link type="primary" @click="deleteFile">删除</el-link>
            </div>
        </div>

        <el-dialog
                title="选择文件"
                :visible.sync="dialogVisible"
                width="700px"
                append-to-body
                :before-close="handleClose">
            <div slot="title">
                我的文件
            </div>

            <div class="selectVideo-content" >
                <div class="all-selectVideo">
                    <p class="selectVideo-tag selected">全部文件 <span class="file-count">({{this.total}})</span> </p>
                </div>

                <div class="selectVideo-list-content">

                    <div class="selectVideo-list" v-loading="loading">
                        <p class="selectVideo-search">
                            <el-input style="width: 180px" v-model="name" clearable placeholder="输入文件名"></el-input>
                            <el-button plain @click="search" class="margin-l-sm">筛选</el-button>
                        </p>
                        <div v-for="file in files" v-bind:key="file.id" >
                            <div class="selectVideo-item" v-if="file.status ===  3" @click="onSelectFile(file)">
                                <span v-if="selectFileId == file.id" class="select-icon"></span>
                                <div class="selectVideo-item-top">
                                    <span class="selectVideo-icon"><img :src="file.pic"/> </span>
                                    <div class="selectVideo-info">
                                        <p class="selectVideo-info-title">
                                            <el-tooltip effect="dark" :content="file.name" placement="top">
                                                <span>{{file.name}}</span>
                                            </el-tooltip>
                                        </p>
                                        <p class="selectVideo-info-size">大小：{{parseFloat((file.size/1024)/1024).toFixed(2) + 'M'}}
                                            时长：{{s_to_hs(file.duration)}}</p>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                            <div class="selectVideo-item" v-if="file.status !==  3" >
                                <span v-if="selectFileId == file.id" class="select-icon"></span>
                                <div class="selectVideo-item-top">
                                    <span class="selectVideo-icon"><img :src="file.pic"/> </span>
                                    <div class="selectVideo-info">
                                        <p class="selectVideo-info-title">
                                            <el-tooltip effect="dark" :content="file.name" placement="top">
                                                <span>{{file.name}}</span>
                                            </el-tooltip>
                                        </p>
                                        <p class="selectVideo-info-size">视频转码中...</p>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>


                        <div class="selectVideo-search" >
                            <el-button slot="trigger" size="small" @click="toUploadVideo" type="success">上传视频</el-button>
                            <div class="fl-r" >
                                <el-pagination
                                        small
                                        background
                                        :current-page="page"
                                        :page-sizes="[10, 20]"
                                        :page-size="pageSize"
                                        layout="total, prev, pager, next "
                                        @size-change="setPageSize"
                                        @current-change="setPage"
                                        :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="select">确 定</el-button>
              </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../utils/tools";

    export default {
        name: "selectVideo",
        data(){
            return{
                isSelect:false,
                dialogVisible: false,
                files:[],
                name:'',
                page:1,
                pageSize:10,
                total:0,
                loading:true,
                selectFileId:0,
                selectFileName:'',
                selectFile:{},
                uploadUrl:'',
                uploadData:{},
                uploading:false,
                imageUrl:config.imageUrl,
            }
        },
        methods:{
            ...mapActions('video',['getVideoList']),
            ...mapActions('common',['getOssInfo']),
            ...mapActions('video',['getVideoList']),
            select(){
                if(this.selectFileId == 0)
                {
                    this.$message.error('请选择文件');
                    return
                }
                this.dialogVisible = false
                this.isSelect = true
                this.$emit('onSelect',this.selectFile)
                console.log(this.selectFile)
            },
            handleClose(done) {
                done();
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            onSelectFile(file){
                this.selectFileId = file.id
                this.selectFileName = file.name
                this.selectFile = file
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                }
                if(this.name!= '')
                {
                    data.name = this.name
                }
                this.getVideoList(data).then(res => {
                    this.files = res.data.list
                    this.total = res.data.total
                    this.loading = false
                })
            },
            search(){
                this.page = 1
                this.getList()
            },
            reelectFile(){
                this.dialogVisible = true
            },
            deleteFile(){
                this.isSelect = false
                this.$emit('onDelete')
            },
            toUploadVideo(){
                this.$router.push('/video/add')
            },
            s_to_hs(s){
                return tools.sToTime(s)
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>
    .select-file-info{
        padding: 20px;
        background-color: #fafafa;
        width: 160px;
    }
    .select-file-info-name{
        width: 100%;
        color: #333;
        line-height: 20px;
        font-size: 12px;
        word-break:break-all
    }
    .select-file-info-option{

    }
    .el-divider__text, .el-link{
        font-size: 12px;
    }
    .el-dialog__header{
        padding:20px;
    }
    .el-dialog__body{
        padding:0
    }
    .selectVideo-content{
        height: 528px;
        border-bottom: 1px solid #ebebeb;
        border-top: 1px solid #ebebeb;
    }
    .all-selectVideo{
        padding:20px 10px;
        float: left;
        height: 490px;
        overflow-y: scroll;
    }
    .selected{
        background: #f7f7f7;
    }
    .selectVideo-tag{
        height: 32px;
        color: #333;
        font-size: 12px;
        padding : 0 10px;
        line-height: 32px;
        cursor: pointer;
        color: #999;
    }
    .file-count{
        margin-left: 5px;
        font-size: 12px;
    }
    .selectVideo-search{
        width: 100%;
        flex-grow: 2;
        padding-bottom:10px
    }
    .selectVideo-list-content{
    }
    .selectVideo-list{
        padding:20px 10px;
        display: flex;
        flex-wrap:wrap;
        justify-content:space-between
    }
    .el-card__body{
        padding:10px
    }
    .select-icon{
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 30px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAadJREFUSInF1z1PFEEYAOAHEBIbf8FBQiF/gQIVExYj8Y6Y0HhGqSwEWwu1l2gvhsRGYiRobEDjBxhjZaGUYEzESKWVRqMFCeBZDAYT9nJ7y5J5y5mdeZ/dmZ13t8Vk7RquixStmMAl/IkFgNsYxWYsANzHCNZjAWAep/ArFgBeIcH3IhOd6OTnhWwAeIt+fC0ieVLiRYXLb7IDYBlH8XkvyQc7WRzmzAJ33jcHgE84gpShO/G8zLme3e0DJRYqVBd5sJo+thEAvuAYlupdMLfGvYTT3TttSYmXwyH57Mf6k7eYrGUwgEOYw/G0zpNdPCvT+4jfG6xUw2Ovd+d5AHAQD1FO60xKYb2h8pQna40nbBYA7ZhGNa3z7GE62rj7IdtkeQDQhlu4mGfw/5FlE6bFFsZxMxYAariCq7EA/+IGxuQs50UAYArnsRELADNylPMiAfAYQ5oo50UD4DUG8C0WAN7JWM73CwAr6NOgnO8nwHbyvm1MFABhGfqFr6woAMKGTIQNGgVAeDWHhFc1CoBwSI0I/yBRAITjelQ4vh2IACAUrjH8+Av5AmCiEP+iwQAAAABJRU5ErkJggg==);
    }
    .selectVideo-item{
        width: 260px;
        height: 72px;
        margin-bottom: 10px;
        border: 1px solid #ebebeb;
        cursor: pointer;
        position: relative;
        line-height: 1;
    }
    .selectVideo-icon{
        float: left;
        width: 50px;
        height: 50px;
        overflow: hidden;
    }
    .selectVideo-icon img{
        width: 100%;
        height: 100%;
    }
    .selectVideo-info{
        float: left;
        margin-left:10px
    }
    .selectVideo-item-top{
        padding: 12px 10px 12px 12px;
    }
    .selectVideo-info-title{
        color: #333;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
        width: 170px;
        height: 20px;
        overflow: hidden;
    }
    .selectVideo-info-size{
        color: #999;
        font-size: 12px;
    }
    .selectVideo-option{
        height: 36px;
        background: #fafafa;
        border-top:1px solid #ebebeb;

    }
    .clear{
        clear: both;
    }
</style>
